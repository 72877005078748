.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App {
  background-color: rgba(217, 210, 196, 0.35);
}
